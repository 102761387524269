<template>
  <span class="vux-label-desc"><slot></slot></span>
</template>

<script>
export default {
  name: 'inline-desc'
}
</script>

<style>
.vux-label-desc {
  font-size:14px;
  color:#666;
}
</style>
