var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vux-selector weui-cell",class:{
    'weui-cell_select': !_vm.readonly,
    'weui-cell_select-after': _vm.title
  }},[(_vm.title)?_c('div',{staticClass:"weui-cell__hd"},[_c('label',{staticClass:"weui-label",class:_vm.labelClass,style:(_vm.cleanStyle({
        width: _vm.$parent.labelWidth,
        textAlign: _vm.$parent.labelAlign,
        marginRight: _vm.$parent.labelMarginRight
      })),attrs:{"for":("vux-selector-" + _vm.uuid)},domProps:{"innerHTML":_vm._s(_vm.title)}})]):_vm._e(),(!_vm.readonly)?_c('div',{staticClass:"weui-cell__bd"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"weui-select",style:(_vm.cleanStyle({
        direction: _vm.direction,
        color: _vm.color
      })),attrs:{"id":("vux-selector-" + _vm.uuid),"name":_vm.name},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.showPlaceholder)?_c('option',{domProps:{"value":_vm.value === null ? 'null' : '',"selected":_vm.isEmptyValue(_vm.value) && !!_vm.placeholder}},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_c('v-no-ssr',[(_vm.fixIos)?_c('option',{attrs:{"disabled":""}}):_vm._e()]),_vm._l((_vm.processOptions),function(one){return _c('option',{domProps:{"value":one.key}},[_vm._v(_vm._s(_vm.direction === 'rtl' ? one.value + '‎' : one.value))])})],2)]):_c('div',{staticClass:"weui-cell__ft vux-selector-readonly"},[_vm._v(" "+_vm._s(_vm._f("findByKey")(_vm.value,_vm.processOptions))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }